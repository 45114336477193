import { ReactComponent as Logo } from 'assets/svg/dwave_color.svg'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

interface IHeader {
  className?: string
}

const Header = ({ className }: IHeader) => {
  return (
    <header
      className={twMerge(
        clsx(
          'fixed z-[2] w-full h-[72px] md:h-[64px] flex bg-white px-[56px] items-center justify-start md:justify-center',
          className
        )
      )}
    >
      <Logo className="flex-shrink-0 h-[33px] w-auto md:h-[40px]" />
    </header>
  )
}

export default Header
