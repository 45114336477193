import { useTranslation } from 'react-i18next'
import twMerge from 'utils/twMerge'
import { ReactComponent as Logo } from 'assets/svg/dwave_white.svg'
import clsx from 'clsx'

interface IFullPageLoading {
  className?: string
}

const FullPageLoading = ({ className }: IFullPageLoading) => {
  const { t } = useTranslation()
  return (
    <div
      className={twMerge(
        clsx(
          'fixed flex flex-col top-0 left-0 h-full w-full bg-primary-dark items-center justify-center',
          className
        )
      )}
    >
      <Logo className="w-[250px] h-auto opacity-75" />
      <span className="text-white text-body-20 mt-8">
        {t('common.loading')}
      </span>
    </div>
  )
}

export default FullPageLoading
