import {
  forwardRef,
  PropsWithChildren,
  useRef,
  useEffect,
  useCallback
} from 'react'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

interface IFormItem {
  label?: string
  required?: boolean
  children?: React.ReactNode
  errorMsg?: string
  className?: string
}

const DEFAULT_FORM_ITEM_HEIGHT = 22

const FormItem = forwardRef<HTMLLabelElement, PropsWithChildren<IFormItem>>(
  ({ label, required, children, errorMsg, className }, ref) => {
    const formItemInnerRef = useRef<HTMLElement | null>(null)
    const errorRef = useRef<HTMLSpanElement | null>(null)

    const handleFormItemInnerPadding = useCallback(() => {
      const errorRefHeight = errorRef.current?.clientHeight || 0
      formItemInnerRef.current?.style.setProperty(
        '--padding-bottom',
        `${
          errorRefHeight > DEFAULT_FORM_ITEM_HEIGHT
            ? errorRefHeight + 3
            : DEFAULT_FORM_ITEM_HEIGHT
        }px`
      )
    }, [])

    useEffect(() => {
      handleFormItemInnerPadding()
      window.addEventListener('resize', handleFormItemInnerPadding)
      return () => {
        window.removeEventListener('resize', handleFormItemInnerPadding)
      }
    }, [errorMsg, handleFormItemInnerPadding])

    return (
      <label
        style={
          {
            '--padding-bottom': '22px'
          } as React.CSSProperties
        }
        className={twMerge(
          clsx(
            'relative inline-flex flex-col pb-[var(--padding-bottom)]',
            className
          )
        )}
        ref={(el) => {
          if (el) formItemInnerRef.current = el
          if (ref && typeof ref === 'function') ref(el)
        }}
      >
        {label && (
          <span
            className={clsx(
              'inline-flex mb-[8px] text-body-20 after:content-["*"] after:text-red after:ml-[6px] after:self-start after:align-top after:text-body-14',
              {
                'after:hidden': !required,
                'after:inline-block': required
              }
            )}
          >
            {label}
          </span>
        )}
        {children}
        {errorMsg && (
          <span className="absolute left-0 bottom-0 flex text-body-12 text-red">
            {errorMsg}
          </span>
        )}
      </label>
    )
  }
)

FormItem.displayName = 'FormItem'

export default FormItem
