import { forwardRef, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Arrow } from 'assets/svg/dropdown_arrow_down.svg'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

interface ISelector {
  disabled?: boolean
  selectorWidth?: number
  error?: boolean
  placeholder?: string
  value?: string
  options: { label: string; value: string }[]
  onChange?: (value: string) => void
  className?: string
}

const Selector = forwardRef<HTMLSelectElement, ISelector>(
  (
    {
      disabled,
      error,
      selectorWidth = 150,
      options = [],
      placeholder = 'placeholder.please_select',
      value,
      onChange,
      className,
      ...remaining
    },
    ref
  ) => {
    const { t } = useTranslation()
    return (
      <div
        className={twMerge(
          clsx(
            'flex relative items-center cursor-pointer text-body-16 outline-none py-[12px] px-[10px] h-auto border border-solid rounded-[8px]',
            `${
              error
                ? 'border-red hover:border-red'
                : 'border-gray hover:border-primary'
            }`,
            `${disabled ? 'bg-[#f5f5f5]' : 'bg-white'}`,
            className,
            { 'text-gray': !disabled, 'text-gray/25': disabled }
          )
        )}
      >
        <span
          className={clsx(
            `${!value && options.length > 0 ? 'text-black/50' : 'text-black'}`
          )}
        >
          {value && options.length > 0
            ? `${options.filter((option) => option.value === value)[0]?.label}`
            : t(placeholder)}
        </span>
        <Arrow className="absolute w-[32px] h-[32px] right-[5px] text-body-20 text-gray mt-0 top-[50%] -translate-y-[50%]" />
        <select
          ref={ref as Ref<HTMLSelectElement>}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange && onChange(e.target.value)
          }}
          className={clsx(
            `block w-full m-0 p-0 absolute top-0 right-0 left-0 bottom-0 opacity-0 appearance-none text-body-16 text-black outline-none`,
            `${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`
          )}
          {...remaining}
        >
          <option value="" disabled>
            {t(placeholder)}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }
)
export default Selector
