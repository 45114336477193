import { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, FieldValues, Controller } from 'react-hook-form'
import emailjs from '@emailjs/browser'
import FormItem from 'components/block/formItem'
import Input from 'components/block/input'
import Button from 'components/block/button'
import Textarea from 'components/block/textarea'
import Selector from 'components/block/selector'
import validEmail from 'utils/validEmail'
import validPhone from 'utils/validPhone'

import {
  EMAIL_SERVICE_ID,
  EMAIL_TEMPLATE_ID,
  EMAIL_USER_ID
} from 'assets/constant'

const BUNDLE_ID_MAP = {
  MEETING_INK: {
    STRING: 'meeting-ink',
    NAME: 'Meeting Ink 會議系統',
    VALUE: '2'
  },
  NOISE_ERASER: {
    STRING: 'noise-eraser',
    NAME: 'Noise Eraser 智能降噪',
    VALUE: '1'
  }
}

const Home = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const OPTION_PRODUCTS = useMemo(
    () => [
      {
        value: BUNDLE_ID_MAP.MEETING_INK.STRING,
        label: BUNDLE_ID_MAP.MEETING_INK.NAME
      },
      {
        value: BUNDLE_ID_MAP.NOISE_ERASER.STRING,
        label: BUNDLE_ID_MAP.NOISE_ERASER.NAME
      },
      {
        value: 'other',
        label: t('common.other')
      }
    ],
    [t]
  )

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      company: '',
      email: '',
      phone: '',
      product: '',
      message: ''
    },
    mode: 'onChange'
  })

  const handleCheckBundleId = useCallback(() => {
    const bundleId = query.get('bundleId')

    if (bundleId) {
      const bundleIdString = Object.values(BUNDLE_ID_MAP).find(
        (item) => item.VALUE === bundleId
      )?.STRING

      if (bundleIdString) {
        setValue('product', bundleIdString)
      }
    }
  }, [query, setValue])

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    try {
      await emailjs.send(
        EMAIL_SERVICE_ID!,
        EMAIL_TEMPLATE_ID!,
        data,
        EMAIL_USER_ID!
      )
      setIsLoading(false)
      reset()
      alert('Message sent!')
    } catch (error) {
      alert('Please try again later')
      setIsLoading(false)
      reset({
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      })
    }
  })

  useEffect(() => {
    handleCheckBundleId()
  }, [handleCheckBundleId])

  return (
    <section className="flex w-full relative pb-[100px]">
      <div className="mt-[40px] relative flex flex-col max-w-[1200px] w-full h-full my-0 mx-auto z-[1] md:px-[24px]">
        <h3 className="text-body-24 font-medium">{t('common.contact')}</h3>
        <form className="flex flex-col gap-[12px]" onSubmit={onSubmit}>
          <div className="mt-[40px] mx-[-32px] md:mx-0">
            <FormItem
              {...{
                label: t('contact_us.name'),
                required: true,
                className:
                  'mx-[32px] mb-[12px] w-[calc(50%-64px)] md:mx-0 md:w-full',
                errorMsg: ((errors?.name?.type === 'validate' &&
                  errors.name.message) ||
                  '') as string
              }}
            >
              <Input
                {...register('name', {
                  validate: (value) =>
                    value.trim().length === 0
                      ? t('common.error.required')
                      : true
                })}
                error={!!errors?.name}
              />
            </FormItem>
            <FormItem
              {...{
                label: t('contact_us.company_name'),
                className:
                  'mx-[32px] mb-[12px] w-[calc(50%-64px)] md:mx-0 md:w-full',
                errorMsg: ((errors?.company?.type === 'validate' &&
                  errors.company.message) ||
                  '') as string
              }}
            >
              <Input {...register('company')} error={!!errors?.company} />
            </FormItem>
            <FormItem
              {...{
                label: t('contact_us.email'),
                required: true,
                className:
                  'mx-[32px] mb-[12px] w-[calc(50%-64px)] md:mx-0 md:w-full',
                errorMsg: ((errors?.email?.type === 'validate' &&
                  errors.email.message) ||
                  '') as string
              }}
            >
              <Input
                {...register('email', {
                  validate: (value) =>
                    value.trim().length === 0
                      ? t('common.error.required')
                      : validEmail(value) || t('common.email.error.format')
                })}
                error={!!errors?.email}
              />
            </FormItem>
            <FormItem
              {...{
                label: t('contact_us.phone'),
                className:
                  'mx-[32px] mb-[12px] w-[calc(50%-64px)] md:mx-0 md:w-full',
                errorMsg: ((errors?.phone?.type === 'validate' &&
                  errors.phone.message) ||
                  '') as string
              }}
            >
              <Input
                {...register('phone', {
                  validate: (value) =>
                    value.trim().length === 0
                      ? true
                      : validPhone(value) || t('common.phone.error.format')
                })}
                error={!!errors?.phone}
              />
            </FormItem>
            <FormItem
              {...{
                label: t('contact_us.product'),
                className:
                  'mx-[32px] mb-[12px] w-[calc(50%-64px)] md:mx-0 md:w-full',
                required: true,
                errorMsg: ((errors?.product?.type === 'validate' &&
                  errors.product.message) ||
                  '') as string
              }}
            >
              <Controller
                {...{
                  control,
                  name: 'product',
                  rules: {
                    validate: (value) => {
                      return value.trim().length === 0
                        ? t('common.error.required')
                        : true
                    }
                  },
                  render: ({ field: { onChange, ref, value } }) => (
                    <Selector
                      {...{
                        ref,
                        value,
                        options: OPTION_PRODUCTS,
                        onChange,
                        error: !!errors?.product
                      }}
                    />
                  )
                }}
              />
            </FormItem>
            <FormItem
              {...{
                label: t('contact_us.message'),
                required: true,
                className:
                  'mx-[32px] mb-[12px] w-[calc(100%-64px)] md:mx-0 md:w-full',
                errorMsg: ((errors?.message?.type === 'validate' &&
                  errors.message.message) ||
                  '') as string
              }}
            >
              <Textarea
                className="min-h-[120px]"
                {...register('message', {
                  validate: (value) =>
                    value.trim().length === 0
                      ? t('common.error.required')
                      : true
                })}
                error={!!errors?.message}
              />
            </FormItem>
          </div>
          <div className="flex justify-center items-center w-full">
            <Button loading={isLoading} className="min-w-[150px]">
              <span>{t('common.submit')}</span>
            </Button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Home
