import { createTailwindMerge } from 'tailwind-merge'

const twMerge = createTailwindMerge(() => ({
  cacheSize: 500,
  theme: {
    screens: ['3xl', '2xl', 'xl', 'lg', 'ml', 'md', 'sm', 'xs', '2xs', '3xs'],
    colors: [
      'primary',
      'primary-dark',
      'primary-tint',
      'secondary',
      'secondary-dark',
      'secondary-tint',
      'currentColor',
      'transparent',
      'black',
      'white'
    ],
    fontSize: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'h7',
      'body-48',
      'body-40',
      'body-38',
      'body-32',
      'body-30',
      'body-28',
      'body-24',
      'body-20',
      'body-18',
      'body-16',
      'body-14',
      'body-12',
      'button-lg',
      'button-md',
      'button-sm'
    ],
    borderRadius: ['32', '24']
  },
  classGroups: {},
  conflictingClassGroups: {}
}))

export default twMerge
