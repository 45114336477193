import { forwardRef, ChangeEvent } from 'react'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

interface ITextarea {
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: string
  error?: boolean
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextarea>(
  (
    { placeholder, disabled, value, error, onChange, className, ...props },
    ref
  ) => {
    return (
      <textarea
        {...{
          ref,
          disabled,
          value,
          onChange,
          placeholder,
          ...props
        }}
        className={twMerge(
          clsx(
            `w-full inline-flex p-[12px] border-solid border rounded-[8px] outline-none min-w-[80px] text-body-16 ${
              error
                ? 'focus:border-red border-red'
                : 'focus:border-primary border-gray'
            } ${className}`
          )
        )}
      />
    )
  }
)

export default Textarea
