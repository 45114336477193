import { forwardRef, ChangeEvent } from 'react'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

interface IInput {
  placeholder?: string
  disabled?: boolean
  value?: string
  error?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const Input = forwardRef<HTMLInputElement, IInput>(
  ({ placeholder, disabled, value, error, onChange, ...props }, ref) => {
    return (
      <input
        {...{
          ref,
          disabled,
          value,
          onChange,
          placeholder,
          ...props
        }}
        className={twMerge(
          clsx(
            `w-full inline-flex p-[12px] border-solid border rounded-[8px] outline-none min-w-[80px] text-body-16 ${
              error
                ? 'focus:border-red border-red'
                : 'focus:border-primary border-gray'
            }`
          )
        )}
      />
    )
  }
)

export default Input
