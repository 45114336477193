import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import Header from 'components/page/header'
import Footer from 'components/page/footer'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main className="flex-auto pt-[72px] md:pt-[64px]">
        <Routes />
      </main>
      <Footer />
    </BrowserRouter>
  )
}

export default App
