import { useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'
import {
  FACEBOOK_LINK,
  MEDIUM_LINK,
  LINKEDIN_LINK,
  SOVIA_URL,
  NOISE_ERASER_PRODUCT_URL,
  DWAVE_OFFICIAL_URL
} from 'assets/constant'
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg'
import { ReactComponent as MediumIcon } from 'assets/svg/medium.svg'
import { ReactComponent as LinkedinIcon } from 'assets/svg/linkedin.svg'

interface IFooterItem {
  id: number
  title: string
  children: {
    name: string
    onClick?: () => void
    href?: string
    outLink: boolean
    icon?: React.ReactNode
    target?: string
  }[]
}

interface IFooter {
  className?: string
}

const Footer = ({ className }: IFooter) => {
  const { t, i18n } = useTranslation()
  const isZhTW = i18n.language === 'zh-TW'

  const handleChangeLanguage: (lng: string) => void = useCallback(
    (lng) => {
      i18n.changeLanguage(lng)
    },
    [i18n]
  )

  const items: IFooterItem[] = useMemo(
    () => [
      {
        id: 0,
        title: 'footer.deep_wave',
        children: [
          {
            name: 'footer.about',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}about`,
            outLink: true
          },
          {
            name: 'footer.technologies',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}solutions`,
            outLink: true
          },
          {
            name: 'footer.career',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}jobs`,
            outLink: true
          },
          {
            name: 'footer.news',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}news`,
            outLink: true
          }
        ]
      },
      {
        id: 1,
        title: 'footer.products',
        children: [
          {
            name: 'common.sovia',
            href: SOVIA_URL,
            outLink: true
          },
          {
            name: 'common.noise_eraser',
            href: `${NOISE_ERASER_PRODUCT_URL}${isZhTW ? '' : '/en'}`,
            outLink: true
          }
        ]
      },
      {
        id: 2,
        title: 'footer.service',
        children: [
          {
            name: 'footer.faq',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}faq`,
            outLink: true
          },
          {
            name: 'footer.privacy',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}privacy-policy`,
            outLink: true
          },
          {
            name: 'footer.terms',
            href: `${DWAVE_OFFICIAL_URL}${isZhTW ? '/' : '/en/'}terms`,
            outLink: true
          }
        ]
      },
      {
        id: 3,
        title: 'common.social_media',
        children: [
          {
            name: 'common.facebook',
            href: FACEBOOK_LINK,
            outLink: true,
            icon: (
              <FacebookIcon className="w-[16px] h-[16px] mr-[12px] sm:w-[30px] sm:h-[30px] shrink-0" />
            )
          },
          {
            name: 'common.medium',
            href: MEDIUM_LINK,
            outLink: true,
            icon: (
              <MediumIcon className="w-[16px] h-[16px] mr-[12px] sm:w-[30px] sm:h-[30px] shrink-0" />
            )
          },
          {
            name: 'common.linkedin',
            href: LINKEDIN_LINK,
            outLink: true,
            icon: (
              <LinkedinIcon className="w-[16px] h-[16px] mr-[12px] sm:w-[30px] sm:h-[30px] shrink-0" />
            )
          }
        ]
      },
      {
        id: 4,
        title: 'footer.language',
        children: [
          {
            name: '中文',
            href: '',
            outLink: false,
            onClick: () => {
              handleChangeLanguage('zh-TW')
            }
          },
          {
            name: 'English',
            href: '',
            outLink: false,
            onClick: () => {
              handleChangeLanguage('en-US')
            }
          }
        ]
      }
    ],
    [isZhTW, handleChangeLanguage]
  )

  return (
    <footer
      className={twMerge(
        clsx(
          'flex w-full flex-col items-center justify-center pt-[46px] pb-[38px] bg-primary-dark text-white',
          className
        )
      )}
    >
      <div className="flex sm:grid w-full max-w-[697px] justify-between sm:pl-[8.5%] sm:pr-[8.5%] sm:grid-areas-footer-mobile sm:gap-y-[100px]">
        {items.map((item, index) => (
          <section
            className={`inline-flex flex-col ${
              ['grid-in-a', 'grid-in-b', 'grid-in-c', 'grid-in-d', 'grid-in-e'][
                index
              ]
            }`}
            key={item.id}
          >
            <div className="font-bold text-body-16 sm:text-body-18">
              {t(item.title)}
            </div>
            <ul className="mt-[25px] sm:mt-[32px]">
              {item.children.map((child, index) => (
                <li key={index} className={`${index !== 0 && 'mt-[16px]'}`}>
                  {child.outLink ? (
                    <a
                      className="text-white flex items-center text-body-16 sm:text-body-18 font-light"
                      {...{
                        href: child.href,
                        rel: 'noopener noreferrer',
                        target: '_blank',
                        ...(child.onClick ? { onClick: child.onClick } : {})
                      }}
                    >
                      {child.icon && child.icon}
                      <span>{t(child.name)}</span>
                    </a>
                  ) : child.href ? (
                    <Link
                      {...{
                        className:
                          'text-white flex items-center text-body-16 sm:text-body-18 font-light',
                        to: child.href,
                        ...(child.onClick ? { onClick: child.onClick } : {})
                      }}
                    >
                      {child.icon && child.icon}
                      <span>{t(child.name)}</span>
                    </Link>
                  ) : (
                    <span className="cursor-pointer" onClick={child.onClick}>
                      {t(child.name)}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </section>
        ))}
      </div>
      <div className="mt-[120px] sm:mt-[80px] text-body-14 sm:text-body-18">
        {t('footer.copyright')}
      </div>
    </footer>
  )
}

export default Footer
