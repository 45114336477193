import { ReactNode, forwardRef } from 'react'
import { Loader2 } from 'lucide-react'
import { cva, type VariantProps } from 'class-variance-authority'
import twMerge from 'utils/twMerge'
import clsx from 'clsx'

export const VARIANTS = {
  PRIMARY: 'PRIMARY'
}

export const SIZES = {
  MD: 'MD',
  SM: 'SM',
  LG: 'LG',
  ICON: 'ICON'
}

const buttonVariants = cva(
  'inline-flex items-center justify-center font-normal transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-40',
  {
    variants: {
      variant: {
        [VARIANTS.PRIMARY]:
          'border-[1px] bg-primary border-primary text-white focus:border-primary-dark hover:border-primary-dark focus:bg-primary-dark hover:bg-primary-dark text-body-16'
      },
      size: {
        [SIZES.LG]: 'rounded-[8px] py-[16px] px-[16px]',
        [SIZES.MD]: 'rounded-[8px] py-[12px] px-[12px]',
        [SIZES.SM]: 'rounded-[8px] py-[8px] px-[8px]',
        [SIZES.ICON]: 'rounded-[100%]'
      }
    },
    defaultVariants: {
      variant: VARIANTS.PRIMARY,
      size: SIZES.MD
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = VARIANTS.PRIMARY,
      size = SIZES.MD,
      loading = false,
      children,
      className,
      ...remaining
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={twMerge(
          clsx(
            buttonVariants({
              variant,
              size,
              className: clsx(className, {
                'pointer-events-none': loading
              })
            })
          )
        )}
        {...remaining}
      >
        {loading && (
          <Loader2
            className={clsx('animate-spin absolute', {
              'opacity-0': !loading,
              'w-9 h-9': size === SIZES.LG,
              'w-7 h-7': size === SIZES.MD,
              'w-5 h-5': size === SIZES.SM
            })}
          />
        )}
        <div
          className={clsx('inline-flex items-center justify-center', {
            'opacity-0': loading
          })}
        >
          {children}
        </div>
      </button>
    )
  }
)

export default Button
