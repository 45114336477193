import { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import FullLoading from 'components/page/fullPageLoading'
import Home from 'pages/home'

const Router = () => {
  return (
    <Suspense fallback={<FullLoading />}>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Suspense>
  )
}

export default Router
