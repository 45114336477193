export const PRODUCTION_MODE =
  process.env.REACT_APP_ENVIRONMENT === 'production'
export const DEV_MODE = process.env.REACT_APP_ENVIRONMENT === 'development'
export const TESTING_MODE = process.env.REACT_APP_ENVIRONMENT === 'testing'
export const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT !== 'production'

export const DWAVE_OFFICIAL_URL = 'https://dwave.cc'
export const FACEBOOK_LINK = 'https://www.facebook.com/DWaveTW/'
export const MEDIUM_LINK = 'https://deepwave.medium.com/'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/dwavetw/'
export const NOISE_ERASER_PRODUCT_URL = 'https://eraser.dwave.cc'
export const SOVIA_URL = 'https://sovia.dwave.cc'

export const EMAIL_SERVICE_ID = PRODUCTION_MODE
  ? process.env.REACT_APP_EMAIL_SERVICE_ID
  : process.env.REACT_APP_EMAIL_SERVICE_ID_STAGE
export const EMAIL_TEMPLATE_ID = PRODUCTION_MODE
  ? process.env.REACT_APP_EMAIL_TEMPLATE_ID
  : process.env.REACT_APP_EMAIL_TEMPLATE_ID_STAGE
export const EMAIL_USER_ID = PRODUCTION_MODE
  ? process.env.REACT_APP_EMAIL_USER_ID
  : process.env.REACT_APP_EMAIL_USER_ID_STAGE
